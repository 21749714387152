require(['jquery', 'mojo-analytics', 'utils', 'mdp-gtm'], function ($, MojoAnalytics) {
  MojoAnalytics.sendEvent({
    eventName: 'Viewed Page',
    eventData: {
      page_name: 'developershomepage',
    },
  });
  var progressbar,
    finalWidth,
    width = 0,
    classToAppend,
    currentWordIndex = 0;
  var words = ['Innovative', 'Futuristic', 'New Age', 'Unique', 'Useful', 'Original', 'Novel', 'Fresh', 'Cutting-edge', 'Powerful', 'Quick', 'Easy-to-use', 'Productive'];
  setInterval(function () {
    $('.phrase').fadeOut(300, function () {
      var html = 'Build <span class="word" style="color: #22B573; display: inline;">'.concat(words[currentWordIndex], '</span> apps');
      currentWordIndex = (currentWordIndex + 1) % words.length;
      $('.phrase').html(html);
      $('.phrase').show();
    });
  }, 3300);

  function frame () {
    if (width >= finalWidth + 49) {
      width = 0;
      $('.step__title--status-icon').removeClass(classToAppend);
      $('#1').show();
      $('#2').hide();
      $('#3').hide();
      $('#previous').attr('disabled', true).addClass('darken');
      $('#next').attr('disabled', false).removeClass('darken');
    } else if (width > finalWidth) {
      width += 0.25;
    } else {
      width += 0.25;
      progressbar.width(width + '%'); // Search for check mark and enable

      if (width === 0) {
        $('#1').show();
        $('#2').hide();
        $('#3').hide();
        $('#previous').attr('disabled', true).addClass('darken');
        $('#next').attr('disabled', false).removeClass('darken');
        $('#previous').addClass('darken');
      } else if (width === 50) {
        $('#2').show();
        $('#1').hide();
        $('#3').hide();
        $('#previous').attr('disabled', false).removeClass('darken');
        $('#next').attr('disabled', false).removeClass('darken');
      } else if (width === 100) {
        $('#3').show();
        $('#2').hide();
        $('#1').hide();
        $('#previous').attr('disabled', false).removeClass('darken');
        $('#next').attr('disabled', true).addClass('darken');
      }

      $steps.each(function (index, node) {
        var $node = $(node);
        var position = $node.data('position');

        if (position <= width) {
          $node.find('.step__title--status-icon').addClass(classToAppend);
        }
      });
    }
  }

  $('#previous').click(function () {
    var current = parseInt(width / 50);

    if (current === 1) {
      width = 0;
      $('#1').show();
      $('#2').hide();
      $('#3').hide();
      $('#previous').attr('disabled', true).addClass('darken');
      $('#next').attr('disabled', false).removeClass('darken');
      $('.step__title--status-icon').removeClass(classToAppend);
    } else if (current === 2) {
      width = 50;
      $('#1').hide();
      $('#2').show();
      $('#3').hide();
      $('#previous').attr('disabled', false).removeClass('darken');
      $('#next').attr('disabled', false).removeClass('darken');
      $('.step__title--status-icon').removeClass(classToAppend);
    }
  });
  $('#next').click(function () {
    var current = parseInt(width / 50);

    if (current === 0) {
      width = 50;
      $('#1').hide();
      $('#2').show();
      $('#3').hide();
      $('#previous').attr('disabled', false).removeClass('darken');
      $('#next').attr('disabled', false).removeClass('darken');
      $('.step__title--status-icon').removeClass(classToAppend);
    } else if (current === 1) {
      width = 100;
      $('#1').hide();
      $('#2').hide();
      $('#3').show();
      $('#previous').attr('disabled', false).removeClass('darken');
      $('#next').attr('disabled', true).addClass('darken');
      $('.step__title--status-icon').removeClass(classToAppend);
    }
  });
  var $bar = $('.bar');
  var $steps = $bar.find('.step');

  if ($bar && $bar.length) {
    classToAppend = 'step__title--status-icon-completed';
    progressbar = $bar.find('.progress__bar');
    finalWidth = progressbar.data('width');

    if (progressbar.length) {
      setInterval(frame, 25);
    }
  }
});

define("mdp-homepage", function(){});

